import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { P2 } from '../Text/P2';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';
import { Heading6, Body2 } from '../Theme/Typography';

const StaffPreviewWrapper = styled.figure`
  color: ${colors.eerie};
  padding: 3rem 0;
  border-right: 1px solid ${colors.staffBor};
  border-bottom: 1px solid ${colors.staffBor};
  background-color: ${colors.staffBackground};
  width: 28.7rem;
  height: 28.7rem;
  text-align: center;

  img {
    max-width: 14rem;
    max-height: 14rem;
    margin: 0 auto 1.7rem;
  }

  figcaption {
    ${Heading6};
    margin-bottom: 1rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    position: relative;
    color: ${colors.staffName};
  }

  p {
    color: ${colors.eerie800};
    letter-spacing: 0.04em;
    font-weight: 400;
    line-height: 148%;
  }

  ${media.mobileWide`
    width: auto;
    height: auto;
    padding: 1.2rem 0;
    img {
      max-width: 8rem;
      max-height: 8rem;
      margin-bottom: 0.5rem;
    }
    figcaption {
      ${Body2};
      margin-bottom: 0.4rem;
    }
    p {
      font-size: 1.2rem;
    }
  `}
`;

export const StaffPreview = ({ src, staffName, position }) => (
  <StaffPreviewWrapper>
    <img alt="" src={src} />
    <figcaption>{staffName}</figcaption>
    <P2>{position}</P2>
  </StaffPreviewWrapper>
);

StaffPreview.propTypes = {
  src: PropTypes.any,
  staffName: PropTypes.string,
  position: PropTypes.string,
};

export default StaffPreview;
