import styled from '@emotion/styled';
import { Layout } from '../components/Layout';
import { H2 } from '../components/Text/H2';
import { P3 } from '../components/Text/P3';
import { bottomUp200 } from '../components/Theme/Animation';
import {
  Welcome,
  Introduction,
  ProductList,
  Location,
  Staffs,
  OurServices,
} from '../containers/HomePage';
import { HAS_ACCESS_TO } from '../utils/constants';
import storage from '../utils/storage';
// import { AppContext } from '../context/AppContext';

export const WelcomeStyle = styled(Welcome)`
  margin-bottom: 5.6rem;

  p {
    animation: ${props =>
      `bottomUp200 0.5s ${props.hasDelay ? '3s' : '0s'} forwards`};
  }

  h2 {
    animation: ${props =>
      `bottomUp200 0.5s ${props.hasDelay ? '3.5s' : '0.5s'} forwards`};
  }

  ${bottomUp200}
`;

const IndexPage = ({ location }) => {
  const hasAccessTo = storage.get(HAS_ACCESS_TO);

  return (
    <Layout location={location} nextPage="Recruiment Page" hasLoading>
      <WelcomeStyle hasDelay={!hasAccessTo}>
        <P3 className="welcome__title" data-scroll data-scroll-repeat>
          WELCOME,
        </P3>
        <H2 className="welcome__content" data-scroll data-scroll-repeat>
          We’re{' '}
          <strong>
            <i>tech</i>
          </strong>{' '}
          experts who explore problems then create the valuable experience and
          commitment products for clients.
        </H2>
      </WelcomeStyle>
      <Introduction />
      <OurServices />
      <ProductList />
      <Staffs />
      <Location />
    </Layout>
  );
};

export default IndexPage;
