import styled from '@emotion/styled';
import { StaffPreview } from '../../components/StaffPreview';
import { H3 } from '../../components/Text/H3';
import { P3 } from '../../components/Text/P3';
import { colors } from '../../components/Theme/Colors';
import { media } from '../../components/Theme/Media';

import JakeJong from '../../../static/images/staffs/Jake Jong.svg';
import Daesun from '../../../static/images/staffs/Daesun.svg';
import Donghwan from '../../../static/images/staffs/Donghwan.svg';
import Bomb from '../../../static/images/staffs/Bomb.svg';
import Esme from '../../../static/images/staffs/Esme.svg';
import ManhVu from '../../../static/images/staffs/ManhVu.svg';
import HungNguyen from '../../../static/images/staffs/HungNguyen.svg';
import TienTran from '../../../static/images/staffs/TienTran.svg';
import HauNguyen from '../../../static/images/staffs/HauNguyen.svg';
import TuanTran from '../../../static/images/staffs/TuanTran.svg';
import TrucHuynh from '../../../static/images/staffs/TrucHuynh.svg';
import NhiLe from '../../../static/images/staffs/NhiLe.svg';

const StaffWrapper = styled.section`
  padding: 10rem 0;

  .staff__intro {
    color: ${colors.staffIntroColor};
    max-width: 114.6rem;
    margin: 0 auto 8rem;
    padding: 0;

    h3 {
      margin-bottom: 3.2rem;
    }
  }

  .staff__list {
    max-width: 114.8rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 0;
    row-gap: 0;
    border-left: 1px solid ${colors.eerie100};
    border-top: 1px solid ${colors.eerie100};
  }

  ${media.mobileWide`
    padding: 6rem 0;
    margin-top: 8rem;


    .staff__intro { 
      padding: 0 1.6rem;
      margin: 0 auto 6rem;
      h3 {
      margin-bottom: 2.4rem;
      }
    }

    .staff__list {
      margin: 0 0.5rem;
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

export const Staffs = () => (
  <StaffWrapper>
    <div className="staff__intro">
      <H3>
        Our Tech staffs are ready to communicate with customers for better
        production!
      </H3>
      <P3>CORE TEAM</P3>
    </div>
    <div className="staff__list">
      <StaffPreview position="CEO" src={JakeJong} staffName="Jake Jeong" />
      <StaffPreview position="CTO" src={Daesun} staffName="Daesun Park" />
      <StaffPreview position="CPO" src={Donghwan} staffName="Donghwan Kim" />
      <StaffPreview
        position="Project Manager"
        src={Bomb}
        staffName="Beomkyu Kang"
      />
      <StaffPreview
        position="Product Manager"
        src={Esme}
        staffName="Esme Choi"
      />
      <StaffPreview
        position="Technical Lead"
        src={ManhVu}
        staffName="Manh Vu"
      />
      <StaffPreview
        position="Project Manager"
        src={HungNguyen}
        staffName="Hung Nguyen"
      />
      <StaffPreview
        position="ERP Manager"
        src={TienTran}
        staffName="Tien Tran"
      />
      <StaffPreview
        position="Technical Lead"
        src={HauNguyen}
        staffName="Hau Nguyen"
      />
      <StaffPreview
        position="Technical Product Manager"
        src={TuanTran}
        staffName="Tuan Tran"
      />
      <StaffPreview
        position="UI UX Desginer"
        src={TrucHuynh}
        staffName="Truc Huynh"
      />
      <StaffPreview position="HR Generalist" src={NhiLe} staffName="Nhi Le" />
    </div>
  </StaffWrapper>
);
