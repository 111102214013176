import styled from '@emotion/styled';
import { media } from '../Theme/Media';
import { Heading4, Heading6 } from '../Theme/Typography';

export const H4 = styled.h4`
  ${Heading4};

  ${media.mobileWide`
    ${Heading6};
  `}
`;
