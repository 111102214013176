import styled from '@emotion/styled';
import { media } from '../../components/Theme/Media';

export const Welcome = styled.section`
  text-align: center;
  padding: 21rem 0 0;
  max-width: 114.5rem;
  margin: 0 auto;

  p,
  h2 {
    position: relative;
    opacity: 0;
  }

  p {
    margin-bottom: 2rem;
  }

  ${media.tabletWide`
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  `}
`;
