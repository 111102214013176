import { useEffect } from 'react';
// import { css } from '@emotion/core';
import styled from '@emotion/styled';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import PropTypes from 'prop-types';
import SingaporeText from '../../../static/images/locations/Singapore.svg';
import SouthKoreaText from '../../../static/images/locations/SouthKorea.svg';
import USText from '../../../static/images/locations/UnitedStates.svg';
import VNText from '../../../static/images/locations/VietNam.svg';
import SingaporeTextDark from '../../../static/images/locations/Singapore_dark.svg';
import SouthKoreaTextDark from '../../../static/images/locations/SouthKorea_dark.svg';
import USTextDark from '../../../static/images/locations/UnitedStates_dark.svg';
import VNTextDark from '../../../static/images/locations/VietNam_dark.svg';
import previewVN from '../../../static/images/locations/location_vn.jpg';
import previewKR from '../../../static/images/locations/preview-kr.jpg';
import previewSG from '../../../static/images/locations/preview-sg.jpg';
import previewUS from '../../../static/images/locations/preview-us.jpg';
import { LocationPreview } from '../../components/LocationPreview';
import { media } from '../../components/Theme/Media';
import { isDesktopScreen } from '../../utils/constants';
import { isDarkMode } from '../../utils/app';

const LocationStyled = styled.div`
  .location_slider {
    & + .location_slider {
      display: none;
    }
  }
  ${media.mobileWide`
    padding: 5rem 0 3rem;
  `}
`;

const locationsList = [
  {
    textImage: isDarkMode ? VNTextDark : VNText,
    address: '81 Cách Mạng Tháng Tám, P.Bến Thành, Quận 1, Hồ Chí Minh 700000',
    phoneNumber: '(+84) 28 6295 8827',
    description:
      'We are situated in Viet Nam, South Korea, Singapore and US. Visit us in one of these office, or drop us a line in one of our channels.',
    locationImage: previewVN,
    unit: 'VN',
  },
  {
    textImage: isDarkMode ? SouthKoreaTextDark : SouthKoreaText,
    address: '16F, 714, Yeongdong-daero, Gangnam-gu, Seoul, Republic of Korea',
    phoneNumber: '',
    description:
      'We are situated in Viet Nam, South Korea, Singapore and US. Visit us in one of these office, or drop us a line in one of our channels.',
    locationImage: previewKR,
    unit: 'SK',
  },
  {
    textImage: isDarkMode ? SingaporeTextDark : SingaporeText,
    address: '21 Heng Mui Keng Terrace, Singapore 119613',
    phoneNumber: '',
    description:
      'We are situated in Viet Nam, South Korea, Singapore and US. Visit us in one of these office, or drop us a line in one of our channels.',
    locationImage: previewSG,
    unit: 'SG',
  },
  {
    textImage: isDarkMode ? USTextDark : USText,
    address: '815-A Brazos ST. #435 Austin, TX78701',
    phoneNumber: '',
    description:
      'We are situated in Viet Nam, South Korea, Singapore and US. Visit us in one of these office, or drop us a line in one of our channels.',
    locationImage: previewUS,
    unit: 'US',
  },
];

export const Location = () => {
  useEffect(() => {
    if (isDesktopScreen) {
      let locationSectionTL = gsap.timeline({
        scrollTrigger: {
          trigger: '.location-wrapper',
          scroller: '#smooth-scroll',
          scrub: true,
          pin: true,
          start: 'top top',
          end: "+=200%"
        },
      });
      locationSectionTL
        .to('.slider_1', { display: 'none' })
        .to('.slider_2', { display: 'block' })
        .to('.slider_2', { display: 'none' })
        .to('.slider_3', { display: 'block' })
        .to('.slider_3', { display: 'none' })
        .to('.slider_4', { display: 'block' });
      ScrollTrigger.addEventListener('refresh', () => window.scroll.update());
      ScrollTrigger.refresh();
    }
  }, []);

  return (
    <LocationStyled className="location-wrapper">
      {isDesktopScreen ? (
        <div className="location-cover">
          <>
            {locationsList.map((item, index) => (
              <div
                key={index}
                className={`location_slider slider_${index + 1}`}
              >
                <LocationPreview index={index} locationsList={locationsList} />
              </div>
            ))}
          </>
        </div>
      ) : (
        locationsList.map((item, index) => (
          <LocationPreview
            key={index}
            index={index}
            locationsList={locationsList}
          />
        ))
      )}
    </LocationStyled>
  );
};

Location.propTypes = {};
