import { useState } from 'react';
import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';
import { colors } from '../../components/Theme/Colors';
import { media } from '../../components/Theme/Media';

const OurServicesWrapper = styled.section`
  .ourservice-list {
    max-width: 114.6rem;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  .ourservice-item {
    max-width: 40%;

    .index {
      position: relative;
      font-size: 1.8rem;
      line-height: 2.4rem;

      &::after {
        content: '';
        position: absolute;
        width: 20rem;
        height: 1px;
        left: 2rem;
        bottom: 0.4rem;
        background: ${colors.eerie200};
      }
    }

    .title {
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 4rem;
      letter-spacing: 0.04em;
      margin: 3rem 0 4rem;
      transform-origin: 0% 0%;
    }

    .description {
      opacity: 0;
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 2.4rem;
      letter-spacing: 0.04em;
      color: ${colors.textColor};
    }

    .description:not(:last-child) {
      margin-bottom: 4rem;
    }
  }

  @media screen and (min-width: 769px) {
    .ourservice-item {
      min-height: 44rem;
      width: 20%;

      &.active {
        width: 40%;
        transition: width 0.5s;

        .index {
          &::after {
            width: calc(100% - 2rem);
            transition: width 1s;
          }
        }

        .title {
          font-size: 4rem;
          animation: main 0.8s;
        }

        @keyframes main {
          0% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }

        .description:not(:last-child) {
          opacity: 1;
          transition: opacity 1s 0.4s;
        }

        .description:last-child {
          opacity: 1;
          transition: opacity 1s 0.6s;
        }
      }
    }
  }

  ${media.mobileWide`
    padding: 0 1.6rem;

    .ourservice-list {
      max-width: 100%;
      flex-direction: column;
      padding: 0 0 4rem;
    }

    .ourservice-item {
      max-width: 100%;
      margin-bottom: 8rem;

      .title {
        margin: 2.1rem 0 1.6rem;
        line-height: 3.0rem;
      }

      .description {
        opacity: 1;
        font-size: 1.6rem;
        line-height: 2.2rem;
      }

      .description:not(:last-child) {
        margin-bottom: 1.6rem;
      }

      .index {
        font-size: 1.4rem;
        line-height: 2.0rem;

        &::after {
          width: calc(100% - 1.6rem - 20px); // 100% - padding right of ourservice - margin left of the border
        }
      }
    }

    .ourservice-item:not(:last-child) {
      margin-bottom: 4rem;
    }
  `}
`;

const services = [
  {
    id: 1,
    title: 'Outsourcing',
    descriptionSt:
      'You just have a good idea and some IT knowledge, but still difficult to develop or design a product yourself?',
    descriptionNd:
      'Leave app and web development and UX/UI design to LECLE’s experts.',
  },
  {
    id: 2,
    title: 'Team building',
    descriptionSt:
      'Are you having trouble maintaining your product? Difficulty hiring developers and designers yourself?',
    descriptionNd: "LECLE's proven staff will be your team.",
  },
  {
    id: 3,
    title: 'Consulting',
    descriptionSt:
      'Not sure where and how to start your IT business? Having a problem with your team or product?',
    descriptionNd:
      "From design to development, LECLE's expert consultants, who are experienced in the IT field, will advise you to ensure that you do not get lost",
  },
];

export const OurServices = () => {
  const [isShowedIndex, setIsShowedIndex] = useState(1);

  return (
    <OurServicesWrapper>
      <div className="hoverable ourservice-list" >
        {services.map(({ id, title, descriptionSt, descriptionNd }) => (
          <div
            key={id}
            className={`ourservice-item ${
              isShowedIndex === id ? 'active' : ''
            }`}
            onMouseEnter={() => (isMobile ? false : setIsShowedIndex(id))}
            data-scroll-speed="2" data-scroll>
            <div className="index">
              {id}
              {'.'}
            </div>
            <div className="title">{title}</div>
            <div className="description">{descriptionSt}</div>
            <div className="description">{descriptionNd}</div>
          </div>
        ))}
      </div>
    </OurServicesWrapper>
  );
};
