import { memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { H6 } from '../Text/H6';
import { P1 } from '../Text/P1';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';
import { Body4 } from '../Theme/Typography';
import Img from '../image';

const ProductPreViewWrapper = styled.article`
  img {
    width: 100%;
    height: auto !important;
  }

  .product-preview__content {
    padding-left: ${({ alignLeft }) => (alignLeft ? 0 : '14.6rem')};
    max-width: 46rem;
    margin-top: 4rem;
    box-sizing: content-box;

    h6 {
      margin-bottom: 1.2rem;
      font-weight: bold;
    }

    p {
      margin-bottom: 2.5rem;
    }

    a {
      ${Body4};
      text-decoration: none;
      color: ${colors.productLink};
      font-weight: 500;
      margin-bottom: 0;
      &:hover {
        background-image: ${colors.gradientOrange};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  ${media.mobileWide`
    margin-top: 8rem;
    .product-preview__content {
      padding: 0 1.6rem;
    }
  `}
`;

export const ProductPreview = memo(
  ({ imgData, name, title, description, block, alignLeft, url, appURL }) => (
    <ProductPreViewWrapper alignLeft={alignLeft} block={block}>
      <a
        rel="noreferrer"
        target="_blank"
        className="hoverable"
        href={appURL || url}
      >
        <figure data-scroll-speed="1" data-scroll>
          <Img imgData={imgData} name={name} />
        </figure>
      </a>
      <div
        className="product-preview__content"
        data-scroll-speed="1"
        data-scroll
      >
        <H6>{title}</H6>
        <P1>{description}</P1>
        {(url || appURL) && (
          <a
            className="hoverable"
            href={appURL || url}
            rel="noreferrer"
            target="_blank"
          >
            {appURL ? 'VISIT APP' : 'VISIT WEBSITE'}
          </a>
        )}
      </div>
    </ProductPreViewWrapper>
  ),
);

ProductPreview.propTypes = {
  imgData: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  block: PropTypes.bool,
  alignLeft: PropTypes.bool,
  url: PropTypes.string,
  appURL: PropTypes.string,
};

ProductPreview.defaultProps = {
  block: true,
  alignLeft: false,
};
