import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { P1 } from '../../components/Text/P1';
import {
  bottomUp30,
  toLeftAuto,
  opacityUp,
  bounce,
} from '../../components/Theme/Animation';
import { media } from '../../components/Theme/Media';
import { colors } from '../../components/Theme/Colors';
import arrow_down_location from '../../../static/images/arrow_down_location.svg';
import arrow_down_location_white from '../../../static/images/arrow_down_location_white.svg';
import { isFullHeightScreen } from '../../utils/constants';
import { isDarkMode } from '../../utils/app';

const LocationWrapper = styled.section`
  .location__container {
    max-width: 114.6rem;
    margin: 0 auto;
    display: flex;
    position: relative;
    height: ${isFullHeightScreen ? '100vh' : 'initial'};

    .location__number {
      position: absolute;
      top: 27%;
      display: inline-flex;
      span {
        letter-spacing: 0.04em;
        font-size: 1.8rem;
        line-height: 2.4rem;
        &:first-of-type {
          position: relative;
          animation: opacityUp 1s ease-in-out;
        }
        &:last-child {
          &:before {
            content: '';
            width: 6rem;
            height: 1px;
            background-color: ${colors.eerie200};
            display: inline-block;
            margin: 0 1.4rem;
          }
        }
      }
    }
    .location__address {
      padding-top: 23rem;
      position: absolute;
      animation: bottomUp30 1s ease-in-out;
      z-index: 1;
      top: 30%;
      transform: translateY(-30%);

      img {
        margin-bottom: 7.6rem;
        position: relative;
      }

      p {
        font-size: 1.8rem;
        max-width: 35rem;
        margin-bottom: 2.4rem;
        line-height: 2.4rem;
        letter-spacing: 0.04em;
        max-width: 32rem;
      }
    }

    .location__preview {
      margin: auto 0 auto auto;

      figcaption {
        p {
          font-size: 1.8rem;
          line-height: 2.4rem;
          letter-spacing: normal;
        }

        margin-bottom: 4rem;
        max-width: 49rem;
      }
      .location__img {
        height: ${isFullHeightScreen ? `calc(100vh - 21.2rem)` : `100%`};
        max-height: 65.6rem;
        img {
          height: 100%;
          animation: toLeftAuto 1s ease-in-out;
        }
      }
    }

    .location__bottom {
      position: absolute;
      bottom: 1%;
      left: 50%;
      animation: bounce 2s infinite;
      @media (min-width: 1441px) {
        bottom: 3rem;
      }
    }
  }

  ${media.tabletWide`
  .location__container {
    .location__number, .location__bottom  {
      display: none;
    }
    }
  `}

  ${media.mobileWide`
  .location__container {
    flex-direction: column-reverse;
    padding: 3rem 0 2rem;
    min-height: unset;
    height: auto;
    .location__address {
      padding: 0 1.6rem;
      width: 100%;
      position: relative;
      top: auto;
      transform: translateY(0);
      
      img {
        width: 50%; 
        margin-bottom: -0.1rem;
        transform: translateY(-1.7rem);
      }

      p {
        margin-bottom: 1rem;
        max-width: unset;
      }
    }

    .location__preview {
      margin: 0;
      figcaption {
        padding: 0 1.6rem;
        max-width: unset;
      }
      .location__img {
        width: 100%;
        height: 100%;
        max-height: unset;
        img {
          max-width: unset;
          width: 100%;
          height: 100%;
        }
        }
    }
  }
  & + section {
    .location__preview {
      figcaption {
        display: none;
      }
    }
  }
  `}
  /* animation */
    ${bottomUp30}
    ${toLeftAuto}
    ${opacityUp}
    ${bounce}
`;

export const LocationPreview = ({ index, locationsList }) => {
  return (
    <LocationWrapper className="location-wrapper">
      <div className="location__container">
        <div className="location__number">
          <span className="location__number_active">{`0${index + 1}.`} </span>
          <span>{`0${locationsList.length}.`}</span>
        </div>
        <div className="location__address">
          <div>
            <img
              alt="vietnam"
              loading="lazy"
              src={locationsList[index].textImage}
            />
            <P1>{locationsList[index].address}</P1>
            <P1>{locationsList[index].phoneNumber}</P1>
          </div>
        </div>
        <div className="location__preview">
          <figure>
            <figcaption>
              <P1>{locationsList[index].description}</P1>
            </figcaption>
            <div className="location__img">
              <img alt="location" src={locationsList[index].locationImage} />
            </div>
          </figure>
        </div>
        <div className="location__bottom">
          <img
            alt="next location"
            className="location__bottom-arrow"
            src={isDarkMode ? arrow_down_location_white : arrow_down_location}
          />
        </div>
      </div>
    </LocationWrapper>
  );
};

LocationPreview.propTypes = {
  index: PropTypes.any,
  locationsList: PropTypes.array,
};
