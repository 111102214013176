import { memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { H4 } from '../Text/H4';
import { media } from '../Theme/Media';
import { Body4 } from '../Theme/Typography';

const CTAWrapper = styled.article`
  padding-left: 14.5rem;
  margin-top: 25rem;

  h3 {
    ${Body4};
    font-weight: 500;
    text-transform: uppercase;
    /* writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg); */
    display: inline-block;
    transform-origin: 0% 0%;
    transform: rotate(-90deg);

    /* margin-bottom: 4rem; */
  }

  h4 {
    max-width: 36.2rem;
    margin-top: 4rem;
  }

  ${media.mobileWide`
    padding-left: 7rem !important;
    padding-right: 2rem;
    margin-top: 0;
    padding-top: 18rem;
  `}
`;

export const CTA = memo(({ title, description }) => (
  <CTAWrapper>
    <h3>{title}</h3>
    <H4>{description}</H4>
  </CTAWrapper>
));

CTA.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
