import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { CTA } from '../../components/CTA';
import { ProductPreview } from '../../components/ProductPreview';
import { media } from '../../components/Theme/Media';

const ProductListWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  /* max-width: 140.5rem; */
  margin: 0 auto;
  padding-bottom: 12rem;

  .product-list__left-side,
  .product-list__right-side {
    flex: 1 1 50%;
    min-width: 0;

    article:not(:last-child) {
      margin-bottom: 15.9rem;
    }
  }

  .product-list__left-side {
    margin-right: 3rem;
    article:nth-of-type(3) {
      margin-right: 9.8rem;
    }
  }

  .product-list__right-side {
    padding-top: 22rem;
    article {
      padding-left: 0;
      margin-left: 9.8rem;
    }

    article:nth-of-type(2) {
      margin-left: 0;
    }
    article:nth-of-type(3) {
      h4 {
        max-width: 100%;
      }
    }
  }

  ${media.mobileWide`
    flex-direction: column;
    padding-bottom: 0;

    .product-list__right-side  {
      padding-top: 0;
    }

    .product-list__left-side {
      margin-right: 0;
      article:nth-of-type(3) {
        margin-right: 0
      }
    }

    article {
      margin-bottom: 0 !important;
    }

    article:first-of-type {
      margin-top: 0;
    }

    .product-list__right-side {
      article {
        margin-left: 0;
      }
    }
  `}
`;

const products = {
  leftSide: [
    {
      hasImg: true,
      title: '9Cam',
      name: 'ninecam',
      appURL:
        'https://play.google.com/store/apps/details?id=com.fantagram.ninecam&hl=en&gl=US',
      description:
        'The simplest way to take pixel-perfect pictures without being worried about the filters, exposure, birghtness and so on.',
    },
    {
      title: 'what we do',
      description:
        'We verify your hypothesis about the user and business and implement the product.',
    },
    {
      hasImg: true,
      title: 'Bombus',
      name: 'bombus',
      url: 'https://bom-bus.com/vn/index.html',
      description:
        'New website of Bombus, a global leading graphic studio with designers and professionals, providing 2D and 3D characters, backgrounds, animations and VFX related graphic designs.',
      block: false,
    },
    {
      hasImg: true,
      title: 'Bell Boy',
      name: 'bellboy',
      description:
        'A modern hotel app to support guests check-in at the reservation quickly and using room services without calling.',
    },
  ],
  rightSide: [
    {
      hasImg: true,
      title: 'CryptoBadge',
      name: 'cryptobadge',
      url: 'https://cryptobadge.app/',
      description:
        'A blockchain-based certificate system which will lead you to identify yourself through expertise, achievements, contributions.',
      alignLeft: true,
    },
    {
      hasImg: true,
      title: 'Devera',
      name: 'devera',
      url: 'https://devera.vn/',
      description:
        'Exclusive learning platform taught by IT expert instructors that help you discover anything from programming languages.',
      alignLeft: true,
    },
    {
      title: 'what we offer',
      description:
        'We offer solutions that are best suited to our customers in an environment where diverse cultures and technologies combined.',
    },
    {
      hasImg: true,
      title: 'Meetera',
      name: 'meetera',
      appURL:
        'https://play.google.com/store/apps/details?id=lecle.co.kr.meetera',
      description:
        'It is open to anyone who would like to host a seminar. Find out which seminars are available around you and get your name listed online!',
      alignLeft: true,
    },
  ],
};

const renderItems = (items, imgData) =>
  items.map(
    ({ title, description, hasImg, block, alignLeft, name, url, appURL }) =>
      hasImg ? (
        <ProductPreview
          key={title}
          alignLeft={alignLeft}
          appURL={appURL}
          block={block}
          description={description}
          imgData={imgData}
          name={name}
          title={title}
          url={url}
        />
      ) : (
        <CTA key={title} description={description} title={title} />
      ),
  );

export const ProductList = () => {
  const imgData = useStaticQuery(graphql`
    query {
      ninecam: file(relativePath: { eq: "products/ninecam.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 705, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bombus: file(relativePath: { eq: "products/bombus.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 607, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bellboy: file(relativePath: { eq: "products/bellboy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 705, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cryptobadge: file(relativePath: { eq: "products/cryptobadge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 607, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      devera: file(relativePath: { eq: "products/devera.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meetera: file(relativePath: { eq: "products/meetera.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 607, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <ProductListWrapper>
      <div className="product-list__left-side">
        {renderItems(products.leftSide, imgData)}
      </div>
      <div className="product-list__right-side">
        {renderItems(products.rightSide, imgData)}
      </div>
    </ProductListWrapper>
  );
};
