import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { H4 } from '../../components/Text/H4';
import { media } from '../../components/Theme/Media';
import Img from '../../components/image';

const IntroductionStyled = styled.section`
  .intro__description {
    max-width: 114.6rem;
    margin: 0 auto;
    padding: 12rem 0 16rem;
    text-align: center;
  }

  ${media.mobileWide`
    .intro__description {
      padding: 8rem 1.5rem;
    }
  `}
`;

export const Introduction = () => {
  const introImg = useStaticQuery(graphql`
    query {
      welcomeIntro: file(relativePath: { eq: "welcome_intro.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <IntroductionStyled>
      <div
        className="intro__title"
        data-scroll-position="top"
        data-scroll-speed="1"
        data-scroll
      >
        <Img alt="lecle home" imgData={introImg} name="welcomeIntro" />
      </div>
      <div className="intro__description" data-scroll-speed="0.5" data-scroll>
        <H4>
          Lecle is a global software house located in Korea, the United States,
          Singapore, and Vietnam.
        </H4>
        <H4>
          From consulting to planning, design and development, we provide all
          necessary resources.
        </H4>
      </div>
    </IntroductionStyled>
  );
};

Introduction.propTypes = {};
